import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import {
  guestKeys,
  useGuestCache,
} from "src/data/guests/queries/guestQueryCache"
import {
  IGuest,
  IGuestPost,
  IGuestsResponse,
} from "src/data/guests/types/guestTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export enum SortOrder {
  ASCENDING = "asc",
  DESCENDING = "desc",
}
export enum SortKey {
  CHECK_IN = "checkinTime",
  CHECK_OUt = "checkoutTime",
}

export async function deleteGuest(
  homeId: string,
  guestId: string
): Promise<IGuest> {
  const response = await minutApiHttpClient.delete(
    `${API_DEFAULT}/homes/${homeId}/guests/${guestId}`
  )
  const home: IGuest = response.data
  return home
}

async function getGuest(homeId: string, guestId: string): Promise<IGuest> {
  const response = await minutApiHttpClient.get(
    `${API_DEFAULT}/homes/${homeId}/guests/${guestId}`
  )
  const home: IGuest = response.data
  return home
}

// ts-prune-ignore-next
export function useFetchGuest({
  homeId,
  guestId,
  options,
}: {
  homeId: string
  guestId: string
  options?: UseQueryOptions<
    IGuest,
    AxiosError,
    IGuest,
    ReturnType<typeof guestKeys.guest>
  >
}) {
  return useQuery(
    guestKeys.guest({ homeId, guestId }),
    () => getGuest(homeId, guestId),
    {
      ...options,
    }
  )
}

export async function fetchGuests(
  homeId: string,
  limit?: number,
  offset?: number,
  checkin_filter?: {
    from?: Date
    to?: Date
  },
  checkout_filter?: {
    from?: Date
    to?: Date
  },
  sort_options?: {
    order?: SortOrder
    key: SortKey
  }
): Promise<IGuestsResponse> {
  let checkinFilter: { from?: string; to?: string } | undefined = undefined
  let checkOutfilter: { from?: string; to?: string } | undefined = undefined
  if (checkin_filter) {
    checkinFilter = {
      from: checkin_filter.from?.toISOString(),
      to: checkin_filter.to?.toISOString(),
    }
  }
  if (checkout_filter) {
    checkOutfilter = {
      from: checkout_filter.from?.toISOString(),
      to: checkout_filter.to?.toISOString(),
    }
  }
  const response = await minutApiHttpClient.get(
    `${API_DEFAULT}/homes/${homeId}/guests`,
    {
      params: {
        limit,
        offset,
        checkin_filter: checkinFilter,
        checkout_filter: checkOutfilter,
        sort_options,
      },
    }
  )

  return {
    guests: response.data?.guests || [],
    paging: response.data?.paging,
  }
}

export function useFetchGuests(
  homeId: string,
  props?: {
    limit?: number
    offset?: number
    checkin_filter?: {
      from?: Date
      to?: Date
    }
    checkout_filter?: {
      from?: Date
      to?: Date
    }
    sort_options?: {
      order?: SortOrder
      key: SortKey
    }
    options?: UseQueryOptions<
      IGuestsResponse,
      AxiosError,
      IGuestsResponse,
      ReturnType<typeof guestKeys.list>
    >
  }
) {
  const {
    limit,
    offset,
    checkin_filter,
    checkout_filter,
    sort_options,
    options,
  } = props || {}
  return useQuery(
    guestKeys.list(homeId, { limit, offset }),
    () => {
      // TODO WEB-321  Use correct query key
      return fetchGuests(
        homeId,
        limit,
        offset,
        checkin_filter,
        checkout_filter,
        sort_options
      )
    },
    {
      keepPreviousData: true,
      ...options,
    }
  )
}

export function useDeleteGuest({ homeId }: { homeId: string }) {
  const { invalidateGuestList } = useGuestCache()

  return useMutation((guestId: string) => deleteGuest(homeId, guestId), {
    onSuccess: () => {
      invalidateGuestList(homeId)
    },
  })
}

export function usePostGuest() {
  const { invalidateGuestList } = useGuestCache()

  async function postGuest(homeId: string, guest: IGuestPost) {
    const response = await minutApiHttpClient.post<IGuest>(
      `${API_DEFAULT}/homes/${homeId}/guests`,
      guest
    )
    return response.data
  }

  return useMutation(
    ({ homeId, guest }: { homeId: string; guest: IGuestPost }) =>
      postGuest(homeId, guest),
    {
      onSuccess: (_data, { homeId }) => {
        invalidateGuestList(homeId)
      },
    }
  )
}

export function usePutGuest() {
  const { invalidateGuestList } = useGuestCache()

  async function putGuest({ guest }: { guest: IGuest }) {
    const response = await minutApiHttpClient.put<IGuest>(
      `${API_DEFAULT}/homes/${guest.home_id}/guests/${guest.guest_id}`,
      guest
    )
    return response.data
  }

  return useMutation(putGuest, {
    onSuccess: (data) => {
      invalidateGuestList(data.home_id)
    },
  })
}
