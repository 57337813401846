import { useQueryClient } from "@tanstack/react-query"

export const guestKeys = {
  /** Use to target *all* guest data in the cache, with fuzzy matching; matching this
   * way is ineffective and should be used with care */
  all() {
    return ["guest"] as const
  },

  /** Use to target all guest data under a home in the cache */
  home(id: string) {
    return [...this.all(), id] as const
  },

  /** Use to target guest data in cache*/
  guest({ homeId, guestId }: { homeId: string; guestId: string }) {
    return [...this.home(homeId), guestId] as const
  },

  /** Use to target guest list in cache */
  list(homeId: string, params?: { limit?: number; offset?: number }) {
    if (!params) {
      return [...this.home(homeId), "list"] as const
    }
    return [...this.home(homeId), "list", params] as const
  },
}

export function useGuestCache() {
  const queryClient = useQueryClient()

  function invalidateGuestList(
    homeId: string,
    params?: { limit?: number; offset?: number }
  ) {
    queryClient.invalidateQueries(guestKeys.list(homeId, params))
  }

  return {
    invalidateGuestList,
  }
}
